import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Index = () => {
  return (
    <Container fluid>
      <Row className="px-0 bg-white">
        <Col className="px-0 hero text-center text-white">
          <p className="display-1">WOTFI</p>
          <p className="display-6">Just a bit weird gaming community</p>
        </Col>
      </Row>
      <Row className="bg-white">
        <Row>
          <Col className="info d-none d-sm-block d-block d-sm-none d-md-block d-block d-md-none d-lg-block"></Col>
          <Col lg={8} className="px-0">
            <Row>
              <Col className="textbox">
                <h1>Information related to community</h1>
                <p>
                  WOTFI is a gaming community that was founded at 2013.
                  Community has about just over 50 members. We are constantly
                  offering new and special services and benefits to our members.
                  We don't accept every application, because we try to keep our
                  community small and tidy.
                </p>
                <h4>WOTFI as a gaming community</h4>
                <p>
                  We offer our members chance to participate in member-only
                  gaming events! Our members can access our teamspeak server,
                  where members can talk with eachother and play together.
                </p>
                <h4>What we offer to our members?</h4>
                <p>
                  We also offer our members access to our website, where they
                  can find information about our events and other services.
                  Every active member can access their own profile that has
                  large personal web storage (up to 1TB). Also our members have
                  access to their personal email.
                </p>
                <span>Join our Teamspeak to get started! Links above!</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};
export default Index;
