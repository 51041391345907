import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";
var year = new Date().getFullYear();
const Index = () => {
  return (
    <div className="container-fluid footer">
      <Row>
        <Col className="footer">
          <Container className="pb-4">
            <Row>
              <div className="text-center">
                <img
                  src="https://wotfi.ovh/img/community.png"
                  alt=""
                  height="150px"
                />
              </div>
              <div className="text-white">
                <p>Copyright © WOTFI 2013-{year}</p>
              </div>
            </Row>
            <Row>
              <div className="text-center text-white">
                <Link to="/tos" className="link-light">
                  Terms of service
                </Link>
                <span> | </span>
                <Link to="/privacy" className="link-light">
                  Privacy policy
                </Link>
              </div>
            </Row>
          </Container>
        </Col>
      </Row>
    </div>
  );
};
export default Index;
