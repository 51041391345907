import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Apply = () => {
  return (
    <Container fluid className="bg-white">
      <Container>
        <Row>
          <Col>
            <h2 className="display-3 mt-5">Applying to WOTFI Community</h2>
            <h4 className="display-7 mt-5">Hey!</h4>
            <p>
              Welcome to apply as WOTFI Member!
              <br />
              Are you interested in applying?
              <br />
              If you are at least 18 years old you are eligible to apply to our
              community. (Younger appliers may still apply, but may not
              succeed). We are recruiting positive, active and nice people! We
              may request you to join our community just by being active with
              our community!
            </p>
            <h4 className="display-5 mt-5">What is WOTFI?</h4>
            <p>
              In short, WOTFI is a gaming community, that arranges all kinds of
              activities and events to members. Most of events are hosted in
              Tampere or in Helsinki at Finland. Events might include going to
              amuseument park, playing laser tag or just by going to some lan
              event as a community! You can find more info about WOTFI Community
              by visiting the <Link to="/about">about</Link> -page!
            </p>
            <h4 className="display-5 mt-5">How to apply?</h4>
            <p>
              If you are interested in applying to WOTFI community, you can do
              it by joining our Teamspeak server and by disgussing with our
              staff. They will provide you with a link to the application form.
              <br />
              Application processing may take up to 4 weeks, we will notify you
              by sending an email to you about you application process.
            </p>
            <Link to="/application.html" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default Apply;
