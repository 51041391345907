import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Tos = () => {
  return (
    <Container fluid className="bg-white">
      <Container>
        <Row className="pt-4">
          <Col>
            <h2 className="display-1">Terms of Service</h2>
            <p className="fs-5">Welcome to WOTFI Community!</p>
            <p className="fs-5">
              These Terms of Service (“Terms”) govern your use of the WOTFI
              Community website (the “Site”) and any other WOTFI Community
              services, products or applications (collectively, the “Services”).
              Feel free to enjoy the content of the site, but we ask you first
              to read the following terms and conditions.
            </p>
            <p className="fs-5">
              By accessing or using the Services, you agree to be bound by these
              Terms. If you do not agree to these Terms, you may not access or
              use the Services. You are required to periodically review the
              (“Terms”) in order to be up to date on the terms to which you are
              committed.
            </p>
            <p className="fs-5">
              By visiting the site and browsing the site, you agree to these
              Terms and Conditions. If you do not agree to the Terms and
              Conditions, we ask you not to use the site.
            </p>
            <h2 className="display-6">Use of services</h2>
            <p className="fs-5">
              The Services are provided by WOTFI Community. The Services are
              offered by WOTFI Community. You must follow the policies that may
              be available in the Services. Don’t abuse the Services. For
              example, do not try to interfere with the operation of the
              Services or try to use them in any other way than via the
              interface we provide and in accordance with the instructions we
              provide. You have the right to use the Services only as permitted
              by law, including applicable export restrictions. We may suspend
              or discontinue the provision of the Services unless you comply
              with our terms and policies or when investigating suspicion of
              abuse.
            </p>
            <p className="fs-5">
              When you use the Services, you do not transfer the intellectual
              property rights of the Services or the content you use. You can
              access the content of the Services only with the permission of the
              content owner or otherwise permitted by law. These Terms of
              Service do not grant you the right to use any of the brands or
              logos used in the Services. Do not delete, disguise or change any
              legal notices displayed in or in connection with the Services.
            </p>
            <p className="fs-5">
              Our Services show content that WOTFI does not own. Such content
              shall be solely responsible for the entity that made it available.
              We can inspect the content to determine whether it violates the
              law or our policies. We may delete or refuse to present content
              that we believe violates the law or our policies. However, do not
              assume that we will inspect the content of the Services, as we may
              not do so.
            </p>
            <p className="fs-5">
              Some of our Services are available on mobile devices. Do not use
              the Services in a way that disturbs you and prevents you from
              complying with traffic rules or safety laws.
            </p>
            <h2 className="display-6">About Software Related to Services</h2>
            <p className="fs-5">
              We may provide you with software that is necessary to use the
              Services. You may install and use such software if you agree to
              the terms and conditions of the license agreement.
            </p>
            <p className="fs-5">
              Open source software is important to us. Some of the Services
              related software may be offered to you under open source access.
              The open source access terms that we make available to you may
              replace some of these conditions.
            </p>
            <h2 className="display-6">Service modification and deletion</h2>
            <p className="fs-5">
              We are constantly changing and improving the Services. We may add
              or delete functions or features and stop offering the Service
              indefinite or completely.
            </p>
            <p className="fs-5">
              You can stop using the Services at any time, even if we are happy
              to keep you as a part of WOTFI Community. On the other hand, WOTFI
              may stop offering the Services to you at any time or add or create
              new restrictions on the Services.
            </p>
            <p className="fs-5">
              The Services we provide require the processing of personal data.
              If you have reported false information and have not reported it
              separately, we will unfortunately have to remove you from our
              community. You can read more about the personal data we collect
              and how to use it.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Tos;
