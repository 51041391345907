import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Privacy = () => {
  return (
    <Container fluid className="bg-white">
      <Container>
        <Row className="pt-4">
          <Col>
            <h2 className="display-1">Privacy Policy</h2>
            <span>Last Edited on 2022-03-22</span>
            <p>
              At WOTFI, accessible from https://wotfi.ovh/ AND
              https://wotfi.gg/, one of our main priorities is the privacy of
              our visitors. This Privacy Policy document contains types of
              information that is collected and recorded by WOTFI and how we use
              it. If you have additional questions or require more information
              about our Privacy Policy, do not hesitate to contact us. The
              privacy policy can be updated at any time, you yourself are
              responsible for checking it from time to time.
            </p>
            <p className="fs-4">
              What information do we collect about you when you use our website,
              services or programs?
            </p>
            <p>
              When ordering products, registering or using services, you may be
              asked, for example, your name, email address, postal address,
              phone number or other information that allows us to improve your
              experience with our services such as cookies or data about the use
              of the Services. More specifically about these further down.
            </p>
            <p className="fs-4"> Following information is collected:</p>
            <ul>
              <li>
                <p> IP address</p>
              </li>
              <li>
                <p>Your name</p>
              </li>
              <li>
                <p>Your email address</p>
              </li>
              <li>
                <p>Your phone number</p>
              </li>
              <li>
                <p>Your postal address</p>
              </li>
              <li>
                <p>Your country</p>
              </li>
              <li>
                <p>Your date of birth</p>
              </li>
            </ul>
            <p className="fs-4">When do we collect data?</p>
            <p>
              We collect information when you sign up to any of our services,
              order products, use our services, or when you send your
              information to our site.
            </p>
            <p className="fs-4">How do we use your data?</p>
            <p>
              We use the information we collect in various ways, including to:
            </p>
            <ul>
              <li>
                <p>Provide, operate, and maintain our website and services</p>
              </li>
              <li>
                <p>Improve, personalize, and expand our website and services</p>
              </li>
              <li>
                <p>
                  Understand and analyze how you use our website and services
                </p>
              </li>
              <li>
                <p>
                  To identify you and your preferences when you are using our
                  services while logged in
                </p>
              </li>
            </ul>
            <p className="fs-4">Advertising, marketing and third parties</p>
            <p>
              Our services do not include third-party marketing, advertising or
              advertising. Our services include targeted advertising and
              marketing of our own services. Your data is not shared with third
              parties.
            </p>
            <p className="fs-4">Log Files</p>
            <p>
              WOTFI follows a standard procedure of using log files. These files
              log visitors when they visit websites. All hosting companies do
              this and a part of hosting services’ analytics. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users’ movement on the
              website, and gathering demographic information.
            </p>
            <p className="fs-4">Cookies and Web Beacons</p>
            <p>
              Like any other website, WOTFI uses ‘cookies’. These cookies are
              used to store information including visitors’ preferences, and the
              pages on the website that the visitor accessed or visited. The
              information is used to optimize the users’ experience by
              customizing our web page content based on visitors’ browser type
              and/or other information. We do not use web beacons on our
              websites.
            </p>
            <p>
              For more general information on cookies, please read “
              <a
                href="https://www.cookiesandyou.com/"
                target="_blank"
                rel="noreferrer"
              >
                What Are Cookies
              </a>
              ”.
            </p>
            <p className="fs-4">General Data Protection Regulation (GDPR)</p>
            <p>
              We are committed to ensuring that your privacy is protected. We
              have implemented appropriate technology and organizational
              measures to protect your personal information. However, please be
              aware that we cannot guarantee the security of any information you
              transmit to us, and you do so at your own risk.
            </p>
            <p>
              WOTFI Community legal basis for collecting and using the personal
              information described in this Privacy Policy depends on the
              Personal Information we collect and the specific context in which
              we collect the information:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Consent:</strong> We may process your personal
                  information if you have given us specific consent to do so. We
                  need to perform a contract with you to do so.
                </p>
              </li>
              <li>
                <p>
                  <strong>Legitimate Interests:</strong> We may process your
                  personal information when it is reasonably necessary to
                  achieve our legitimate business interests.
                </p>
              </li>
              <li>
                <p>
                  <strong>Vital Interests:</strong> We may process your personal
                  information when we believe it is necessary to protect our
                  vital interests.
                </p>
              </li>
              <li>
                <p>
                  <strong>Legal:</strong> We may process your personal
                  information when we have a good-faith belief that processing
                  is necessary to respond to a legal process, such as a court
                  order or subpoena.
                </p>
              </li>
              <li>
                <p>
                  <strong>Security:</strong> We may process your personal
                  information when we have a good-faith belief that processing
                  is necessary to protect our physical safety or the security or
                  integrity of our systems or information.
                </p>
              </li>
            </ul>
            <p>
              WOTFI Community will retain your personal information only for as
              long as is necessary for the purposes set out in this Privacy
              Policy. We will retain and use your information to the extent
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our policies.
            </p>
            <p>
              If you are a resident of the European Economic Area (EEA), you
              have certain data protection rights. If you wish to be informed
              what Personal Information we hold about you and if you want it to
              be removed from our systems, please contact us.
            </p>
            <p>
              In certain circumstances, you have the following data protection
              rights:
            </p>
            <ul>
              <li>
                <p>
                  <strong>
                    The right to access, update or to delete the information we
                    have on you.
                  </strong>{" "}
                  Whenever made possible, you can access, update or request
                  deletion of your Personal Information directly within your
                  account settings section. If you are unable to perform these
                  actions yourself, please contact us. Please be aware that your
                  information may be transferred to — and maintained on —
                  computers located outside of your state, province, country or
                  other governmental jurisdiction where the data protection laws
                  may differ than those from your jurisdiction.
                </p>
              </li>
              <li>
                <p>
                  <strong>The right of rectification.</strong> You have the
                  right to request that we correct any information you believe
                  is inaccurate. You also have the right to object to us
                  processing your information.
                </p>
              </li>
              <li>
                <p>
                  <strong>The right of restriction.</strong> You have the right
                  to request that we restrict the processing of your
                  information. You can also request that we erase your
                  information from our files.
                </p>
              </li>
              <li>
                <p>
                  <strong>The right to data portability.</strong> You have the
                  right to request that we transfer the information we have on
                  you to another organization unit.
                </p>
              </li>
              <li>
                <p>
                  <strong>The right to withdraw consent.</strong> You also have
                  the right to withdraw your consent at any time. Please note
                  however that some information is mandatory and we may continue
                  to process the Personal Information in this case.
                </p>
              </li>
            </ul>
            <p className="fs-5">Contact us</p>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at security(at)wotfi.ovh.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Privacy;
