import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
function getStaff(
  name,
  inits,
  status,
  jdate,
  stfjdate,
  stfedate = 0,
  position,
  desc
) {
  if (status === 3) {
    return (
      <Col lg={4} md={6}>
        <h5 className="subdiv">{name}</h5>
        <p>
          {position} since {stfjdate} {desc}.
        </p>
      </Col>
    );
  }
  if (status !== "current-staff") {
    return (
      <Col lg={4} md={6}>
        <h5 className="previous-staff">{name}</h5>
        <p>
          {position} starting from {stfjdate} till {stfedate}.
        </p>
      </Col>
    );
  }
  if (stfjdate === -1) {
    return (
      <Col lg={4} md={6}>
        <h3 className={status}>{name}</h3>
        <p>
          {inits}
          <br />
          <span className="fst-lighter fst-italic">
            {position}, {desc}
          </span>
          <br />
          Joined: {jdate}
        </p>
      </Col>
    );
  }
  return (
    <Col lg={4} md={6}>
      <h3 className={status}>{name}</h3>
      <p>
        {inits}
        <br />
        <span className="fst-lighter fst-italic">
          {position}, {desc}
        </span>
        <br />
        Joined: {jdate}
        <br />
        Staff from: {stfjdate}
      </p>
    </Col>
  );
}

const About = () => {
  return (
    <Container fluid className="bg-white">
      <Row className="px-0 bg-white">
        <Col className="px-0 heroabout text-center font-weight-bold text-white">
          <p className="display-4 fw-bold">What is WOTFI?</p>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col className="textbox-about-top">
            <h2 className="display-4">What is WOTFI really made of?</h2>
            <p>
              WOTFI is a multinational gaming community founded in 2013. Our
              community has about just over 50 members, there are fewer active
              members and we reward them by offering them a variety of charge
              free services. A member is counted as active after using community
              services or participating in community events in the past six
              months. Active membership benefits include free cloud storage up
              to 1TB, personal email, work life- and educational guidance, and
              prioritized access to member events and events organized by
              partners. Active members get also benefits from our partners eg.{" "}
              <Link to="//bittivirta.fi/">Bittivirta.fi</Link>.
              <br />
              <span className="fst-lighter fst-italic">
                Over the years, the community has had a total of over 200
                members.
              </span>
            </p>
          </Col>
        </Row>

        <Row className="pt-4">
          <Col lg={7} className="textbox-about">
            <h2 className="display-4">Events</h2>
            <p>
              The community organises events aimed at its members, organised by
              the community itself or through a collaborator. The most common
              events have been various almost yearly game events, to which
              members of the community are invited to enjoy the club. In
              addition, the community has organized various recreational days
              for its members over the years.
            </p>
          </Col>
          <Col className="events d-none d-sm-block d-block d-sm-none d-md-block d-block d-md-none d-lg-block"></Col>
        </Row>

        <Row>
          <Col lg={7} className="textbox-about">
            <h2 className="display-4">Staff</h2>
            <p>
              The order of the community is responsible for the community staff,
              the staff comprises three people. Over the years there have been
              about 18 staff members in total.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="textbox-nomaxw">
            <h3 className="display-5">Current Staff</h3>
            <Row>
              {getStaff(
                "Random67839",
                "Mikko S.",
                "current-staff",
                "Early 2013",
                -1,
                "",
                "Server administrator",
                "Founder"
              )}
              {getStaff(
                "DMCORD",
                "Joni V.",
                "current-staff",
                "25th April 2015",
                "12/2019",
                "",
                "Staff member",
                "WOTFI Cloud Customer Support"
              )}
              {getStaff(
                "Jauska",
                "Niko N.",
                "current-staff",
                "10th March 2016",
                "3/2017",
                "",
                "Staff member",
                "Customer Support"
              )}
            </Row>
            <h3 className="display-5">Previous Staff</h3>
            <Row className="mb-5">
              {getStaff(
                "Bass",
                "",
                "",
                "",
                "3/2015",
                "1/2022",
                "Administrator",
                ""
              )}
              {getStaff(
                "Haapis",
                "",
                "",
                "",
                "11/2014",
                "1/2022",
                "Administrator",
                ""
              )}
              {getStaff(
                "Nopalandia",
                "",
                "",
                "",
                "6/2014",
                "1/2022",
                "Administrator",
                ""
              )}
              {getStaff(
                "YoungWolf",
                "",
                "",
                "",
                "1/2017",
                "1/2017",
                "Moderator",
                ""
              )}
              {getStaff(
                "Koodikissa",
                "",
                "",
                "",
                "9/2015",
                "12/2015",
                "Moderator",
                ""
              )}
              {getStaff(
                "Paahdin",
                "",
                "",
                "",
                "8/2015",
                "12/2020",
                "Administrator",
                ""
              )}
              {getStaff(
                "Markurius",
                "",
                "",
                "",
                "8/2015",
                "11/2015",
                "Moderator",
                ""
              )}
              {getStaff(
                "#Killersmurf",
                "",
                "",
                "",
                "11/2014",
                "7/2015",
                "Moderator",
                ""
              )}
              {getStaff(
                "Kakkukunkku",
                "",
                "",
                "",
                "9/2014",
                "8/2015",
                "Moderator",
                ""
              )}
              {getStaff(
                "Rinksu",
                "",
                "",
                "",
                "9/2014",
                "3/2015",
                "Moderator",
                ""
              )}
              {getStaff(
                "MRmasoni",
                "",
                "",
                "",
                "6/2014",
                "8/2015",
                "Moderator",
                ""
              )}
              {getStaff(
                "Kettu",
                "",
                "",
                "",
                "6/2014",
                "1/2015",
                "Moderator",
                ""
              )}
              {getStaff(
                "Zombi_ripuli",
                "",
                "",
                "",
                "6/2014",
                "6/2014",
                "Moderator",
                ""
              )}
              {getStaff(
                "Marko A.",
                "",
                3,
                "",
                "3/2013",
                "",
                "Officer",
                "at subdivision"
              )}
              {getStaff(
                "Jimi H.",
                "",
                3,
                "",
                "3/2013",
                "",
                "Officer",
                "at subdivision"
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
/* WOTFI on suomalainen peliyhteisö joka on perustettu 2013. Yhteisössämme on noin reilu 50 jäsentä, aktiivisia jäseniä on vähemmän ja palkitsemme heitä tarjoamalla heille erilaisia veloituksettomia palveluita. Jäsen lasketaan aktiiviseksi, kun hän on käyttänyt yhteisön palveluita tai osallistunut yhteisön tapahtumiin viimeisen puolen vuoden aikana. Jäsenetuihin kuuluu jokaiselle aktiiviselle jäsenelle veloitukseton pilvitallennustila 1TB, sähköpostipalvelut, työelämäohjaus sekä priorisoitu pääsy jäsentapahtumiin ja yhteistyökumppaneiden järjestämiin tapahtumiin. Vuosien varrella yhteisössä on ollut jäseniä yhteensä yli 200.

Yhteisö järjestää jäsenilleen suunnattuja tapahtumia, joita yhteisö järjestää itse tai yhteistyökumppanin välityksellä. Tavanomaisimpia tapahtumia ovat olleet erilaiset lähes vuosittain järjestettävät pelitapahtumat, joihin yhteisön jäseniä kutsutaan paikanpäälle nauttimaan seurasta. Lisäksi yhteisö on järjestänyt erilaisia virkistyspäiviä jäsenilleen vuosien varrella.

Yhteisön järjestyksestä vastaa yhteisön henkilöstö, henkilöstöön kuuluu kolme henkilöä. Vuosien varrella henkilöstöä on ollut yhteensä noin 20.
*/
