import "./App.css";
import Navbar from "./assets/Nav";
import Footer from "./assets/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./pages/Main";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Tos from "./pages/Tos";
import ScrollToTop from "./assets/ScrollToTop";
import Apply from "./pages/Apply";
import Deals from "./pages/Deals";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/deals" element={<Deals />} />
        <Route path="*" element={<Index />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
