import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Apply = () => {
  return (
    <Container fluid className="bg-white">
      <Container>
        <Row className="py-5">
          <h2 className="display-3 mb-3">
            Current deals for community members!
          </h2>
          <Col lg={3} className="py-3">
            <Card className="mx-3">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src="./img/hetzner-logo.svg"
                  className="img-fluid p-3"
                />

                <Card.Title>Hetzner</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Free credits for Hetzner!
                </Card.Subtitle>
                <Card.Text>
                  For WOTFI members, we are giving you 20 euros of free credits
                  for Hetzner. Herzner provides virtual- and dedicated servers
                  for individuals and businesses. Only for new Hetzner
                  customers.
                </Card.Text>
                <Card.Link href="https://r.wotfi.ovh/ljfmJ">
                  Visit Hetzner
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <br />
          <Col lg={3} className="py-3">
            <Card className="mx-3">
              <Card.Body>
                <Card.Img
                  variant="top"
                  src="./img/bittivirta-gray.svg"
                  className="img-fluid p-3"
                />

                <Card.Title>Bittivirta</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Free webhotel and cheap game servers!
                </Card.Subtitle>
                <Card.Text>
                  For our members we are giving you free webhotel and cheap game
                  servers. Bittivirta provides great bang for the buck if you
                  deside to stay there!
                </Card.Text>
                <Card.Link href="https://r.wotfi.ovh/1LayQ">
                  Visit Bittivirta
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default Apply;
