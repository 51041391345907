import React from "react";
import { Container, Navbar, Nav, Col, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

const NavigationBar = () => {
  // navigation bar of the page
  return (
    <Container className="site-navbar-container">
      <Row className="align-items-center position-relative text-white site-navbar site-navbar-target pb-0">
        <Col>
          <h2 className="title">
            <Link to="/" className="text-decoration-none text-white">
              <strong>WOTFI Community</strong>
            </Link>
          </h2>
        </Col>
        <Col md>
          <Navbar expand="md">
            <Container>
              <Nav className="ms-auto site-navigation d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block">
                <ul className="site-menu">
                  <li>
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="nav-link">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="//ts.wotfi.gg" className="nav-link">
                      Teamspeak
                    </Link>
                  </li>
                  <li>
                    <Link to="//id.wotfi.ovh" className="nav-link">
                      Login
                    </Link>
                  </li>
                </ul>
              </Nav>
            </Container>
          </Navbar>
        </Col>

        <Col xs={12} md={12} className="sticky">
          <Navbar expand="lg">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="ms-auto navbar-dark"
            />

            <Navbar.Collapse className="site-navigation" id="basic-navbar-nav">
              <Nav className="ms-auto d-lg-block d-lg-none d-xl-block d-xl-none py-0">
                <Row className="pt-5">
                  <div className="text-center">
                    <Link to="/" className="link-light mx-2">
                      Home
                    </Link>
                    <span> </span>
                    <Link to="/about" className="link-light mx-2">
                      About
                    </Link>
                    <span> </span>
                    <Link to="//ts.wotfi.gg" className="link-light mx-2">
                      Teamspeak
                    </Link>
                    <span> </span>
                    <Link to="//id.wotfi.ovh" className="link-light mx-2">
                      Login
                    </Link>
                  </div>
                </Row>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
};
export default NavigationBar;
